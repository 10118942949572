<template>
  <secondary-overlay class="lg:px-20 px-10 pt-10 pb-20">
    <h2>Director’s Statement</h2>
    <p class="pb-20">
      Music has always been an important part of the DNA of the Hotel San José, and the soundtrack to the film reflects it, with an original score by Stephen Barber and Charlie Sexton, and contributions from Fiona Apple, St. Vincent, Leon Bridges, Robert Ellis, Meshell Ndegeocello and others.
    </p>
    <div class="user-select-none pointer-events-none">
      <img src="@/assets/images/music/CW_Robert_Ellis-7303660-2.jpg" alt="picture of Robert Ellis" />
      <img src="@/assets/images/music/CW_Robert_Ellis-7303668.jpg" alt="picture of Robert Ellis" />
      <img
        src="@/assets/images/music/CW_Robert_Ellis+David_Garza+Meshell-4136.jpg"
        alt="picture of Robert Ellis David Garza and Meshell Ndegeocello"
      />
      <img
        src="@/assets/images/music/CW_Robert_Ellis+Meshell-4066.jpg"
        alt="picture of Robert Ellis and Meshell Ndegeocello"
      />
      <img
        src="@/assets/images/music/CW_Robert_Ellis+St_Vincent-4255.jpg"
        alt="picture of Robert Ellis and St. Vincent"
      />
      <img
        src="@/assets/images/music/CW_Robert_Ellis+St_Vincent-4294.jpg"
        alt="picture of Robert Ellis and St. Vincent"
      />
      <img src="@/assets/images/music/DC_BenKweller_0002.jpg" alt="picture of Ben Kweller" />
      <img src="@/assets/images/music/DC_BlackPumas_0004.jpg" alt="picture of Black Pumas" />
      <img src="@/assets/images/music/8AE19500-D395-40D3-9268-B59DE5D22188.jpg" alt="picture of Black Pumas" />
      <img src="@/assets/images/music/DC_StVincent_0003.jpg" alt="picture of  St. Vincent" />
      <img src="@/assets/images/music/DC_StVincent_0006.jpg" alt="picture of  St. Vincent" />
      <img src="@/assets/images/music/IMG_0062.jpg" alt="picture of David Garza and Meshell Ndegeocello" />
      <img src="@/assets/images/music/IMG_0069.jpg" alt="picture of Leon Bridges" />
      <img src="@/assets/images/music/IMG_0088.jpg" alt="picture of David Garza" />
      <img src="@/assets/images/music/IMG_0095.jpg" alt="picture of Eric Burton and Liz Lambert" />
      <img src="@/assets/images/music/IMG_0104.jpg" alt="picture of  St. Vincent" />
      <img src="@/assets/images/music/PoonehGhana_StVincent_1.jpg" alt="picture of  St. Vincent" />
      <img src="@/assets/images/music/PoonehGhana_StVincent_2.jpg" alt="picture of  St. Vincent" />
    </div>
  </secondary-overlay>
</template>

<script>
import SecondaryOverlay from "../components/SecondaryOverlay.vue";
export default {
  components: { SecondaryOverlay },
  methods: {},
};
</script>
<style lang="scss" scoped>
img {
  display: block;
  margin: auto;
  padding-bottom: 2em;
  max-width: 100%;
}
</style>
